<template>
  <div class="content">
    <div class="content__title content__title--party content__title--start">
      <router-link
        :to="{ name: 'passport-interaction-public' }"
        class="button button--light button--no-indent button--small"
      >
        <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M7.70917 13.7082C7.61629 13.8013 7.50595 13.8752 7.38447 13.9256C7.263 13.976 7.13277 14.002 7.00125 14.002C6.86973 14.002 6.7395 13.976 6.61802 13.9256C6.49655 13.8752 6.38621 13.8013 6.29332 13.7082L0.293962 7.70884C0.200846 7.61595 0.126968 7.50562 0.0765611 7.38414C0.0261537 7.26266 0.000206594 7.13243 0.000206606 7.00091C0.000206617 6.86939 0.0261537 6.73916 0.0765611 6.61769C0.126969 6.49621 0.200846 6.38587 0.293962 6.29299L6.29333 0.293626C6.48108 0.105873 6.73573 0.00039471 7.00125 0.000394734C7.26677 0.000394757 7.52142 0.105873 7.70918 0.293626C7.89693 0.481379 8.00241 0.736028 8.00241 1.00155C8.00241 1.26707 7.89693 1.52172 7.70917 1.70948L2.41574 7.00091L7.70917 12.2923C7.80229 12.3852 7.87617 12.4956 7.92658 12.617C7.97698 12.7385 8.00293 12.8688 8.00293 13.0003C8.00293 13.1318 7.97698 13.262 7.92658 13.3835C7.87617 13.505 7.80229 13.6153 7.70917 13.7082Z" fill="#2B93E7"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M16 7.00108C16 7.26627 15.8947 7.5206 15.7071 7.70811C15.5196 7.89563 15.2653 8.00098 15.0001 8.00098L2.00149 8.00098C1.7363 8.00098 1.48197 7.89563 1.29445 7.70811C1.10694 7.5206 1.00159 7.26627 1.00159 7.00108C1.00159 6.73589 1.10694 6.48156 1.29445 6.29405C1.48197 6.10653 1.7363 6.00119 2.00149 6.00119L15.0001 6.00119C15.2653 6.00119 15.5196 6.10653 15.7071 6.29405C15.8947 6.48157 16 6.73589 16 7.00108Z" fill="#2B93E7"/>
        </svg>
      </router-link>
      <h1 class="title title--big title--theme">Добавление представительства</h1>
    </div>
    <form class="form" @submit.prevent="onCheckForm">
      <div class="form__content">
        <div class="form__part">
          <div class="form-group">
            <label for="year_election">Год избрания<sup>*</sup></label>
            <div class="form-control" :class="{ 'form-control--error': $v.form.year_election.$error }">
              <masked-input
                v-model="form.year_election"
                name="year_election"
                mask="1111"
                placeholder="гггг"
                class="form-control__input"
                id="year_election"
                autocomplete="off"
                @blur.native="$v.form.year_election.$touch"
              />
              <div class="form-group__error" v-if="$v.form.year_election.$dirty && !$v.form.year_election.required">
                Обязательное поле
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="year_end">Год окончания</label>
            <div class="form-control">
              <masked-input
                v-model="form.year_end"
                name="year_end"
                mask="1111"
                placeholder="гггг"
                class="form-control__input"
                id="year_end"
                autocomplete="off"
              />
            </div>
          </div>
          <div class="form-group">
            <label>ФИО Представителей<sup>*</sup></label>
            <div class="form-group form-group--minindent" v-for="(item, index) in form.people" :key="index">
              <div class="form-control" :class="{ 'form-control--error': $v.form.people.$each[index].full_name.$error }">
                <input
                  :id="`edu_rank${index}`"
                  type="text"
                  class="form-control__input"
                  :name="`edu_rank${index}`"
                  v-model.trim="item.full_name"
                  @change="$v.form.people.$each[index].full_name.$touch()"
                  placeholder="Фамилия Имя Отчество"
                />
                <button v-if="index > 0" @click="onDeleteItem(index, 'people')" class="link link--absolute"
                        type="button">
                  <img src="@/assets/img/remove-icon.svg" alt="Remove icon">
                </button>
              </div>
              <div class="form-group__error" v-if="$v.form.people.$each[index].full_name.$dirty && !$v.form.people.$each[index].full_name.required">
                Обязательное поле
              </div>
            </div>
            <button @click="onCloneItem('people', { full_name: '' })" class="link" type="button">
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="9" cy="9" r="9" fill="#2B93E7"/>
                <path
                  d="M11.9694 9.67347H9.67347V11.9694C9.67347 12.2219 9.46684 12.4286 9.21429 12.4286C8.96173 12.4286 8.7551 12.2219 8.7551 11.9694V9.67347H6.45918C6.20663 9.67347 6 9.46684 6 9.21429C6 8.96173 6.20663 8.7551 6.45918 8.7551H8.7551V6.45918C8.7551 6.20663 8.96173 6 9.21429 6C9.46684 6 9.67347 6.20663 9.67347 6.45918V8.7551H11.9694C12.2219 8.7551 12.4286 8.96173 12.4286 9.21429C12.4286 9.46684 12.2219 9.67347 11.9694 9.67347Z"
                  fill="white"/>
              </svg>
              <span>Добавить строку</span>
            </button>
          </div>
        </div>
        <div class="form__part">
          <div class="form-group">
            <label for="people_count">Количество представителей</label>
            <div class="form-control">
              <input
                id="people_count"
                type="number"
                min="0"
                class="form-control__input"
                name="people_count"
                placeholder="Введите количество представителей"
                v-model.trim="form.people_count"
                readonly
              />
            </div>
          </div>
          <div class="form-group" :class="{ 'form-group--error': $v.form.file.$error }">
            <label>Подтверждающий документ<sup>*</sup></label>
            <uploader :getFileHash="getFileHash" limit-files="1" :fileUrl="file_url" @remove-file="onRemoveFile" />
            <div class="form-group__error" v-if="$v.form.file.$dirty && !$v.form.file.required">
              Обязательное поле
            </div>
          </div>
        </div>
      </div>
      <div class="holder holder--end">
        <button class="button" type="submit" :class="{ 'preload': loading }" :disabled="$v.form.$error">
          <span>Добавить</span>
          <img src="@/assets/img/preloader.svg" alt="" class="button__preloader"/>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import MaskedInput from 'vue-masked-input'
import Uploader from '@/components/Uploader'

export default {
  name: 'PassportPublicCreate',
  mixins: [ validationMixin ],
  components: {
    MaskedInput,
    Uploader,
  },
  validations: {
    form: {
      year_election: { required },
      file: { required },
      people: {
        $each: {
          full_name: { required },
        }
      }
    },
  },
  data() {
    return {
      form: {
        people: [
          {
            full_name: ''
          },
        ],
        file: '',
      },
      loading: false,
      file_url: `${process.env.VUE_APP_API_URL}files/`,
    }
  },
  computed: {
    userRegion() {
      return this.$store.state.organization
    },
  },
  watch: {
    'form.people': function() {
      this.form.people_count = this.form.people.length
    }
  },
  mounted() {
    if (this.userRegion) this.form.region_org = this.userRegion
  },
  methods: {
    onCheckForm() {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        this.$notify({
          type: 'warn',
          title: 'Внимание!',
          text: 'Проверьте правильность заполнения полей формы.'
        });
      } else {
        this.sendForm()
      }
    },
    sendForm() {
      this.loading = true
      if (!this.form.year_end) this.form.year_end = null
      this.$store.dispatch('passport/POST_PUBLIC_DATA', this.form)
        .then(() => {
          this.loading = false
          this.$router.push({ name: 'passport-interaction-public' })
          this.$notify({
            type: 'success',
            title: 'Успех!',
            text: 'Орган добавлен.'
          });
        })
        .catch(() => {
          this.loading = false
          this.$notify({
            type: 'error',
            title: 'Ошибка!',
            text: 'Ошибка добавления.'
          });
        });
    },
    onCloneItem(name, obj) {
      this.form[name].push(obj)
    },
    onDeleteItem(index, name) {
      this.form[name].splice(index, 1);
    },
    getFileHash(value) {
      this.form.file = value
      this.$v.form.file.$touch()
    },
    onRemoveFile() {
      this.form.file = ''
      this.$v.form.file.$touch()
    },
  }
}
</script>
